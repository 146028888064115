import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {} from '../../redux/actions/inventory/inventory.actions';
import {
    getStockLogs,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    setModal,
} from '../../redux/actions/returns/return.actions';
import { Redirect } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { formattedDate, numberFormat } from '../../Utils/Common';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import StockLogFilter from './StockLog.filter.global';
import ReturnStockModal from './modals/returnStockModal';

function ReturnStockLogTable({
    stock_logs: {
        stock_logs,
        pagination,
        loading,
        sort_order_direction,
        sort_order_name,
        set_row_checked,
        search,
    },
    getStockLogs,
    setMUIPage,
    updateMUISetting,
    getSelectedRows,
    handleSearchInputMUI,
    sortTableByColumn,
    hoverIndex,
    handleHover,
    pageOrigin,
    muiPage,
    setModal,
}) {
    const { hasPermission } = useAccess();
    const canview = hasPermission('inv.setting.inventory.stocks');

    useEffect(() => {
        getStockLogs(muiPage, null, pageOrigin, 'out'); // Default filter is 'out'
    }, []);

    const handleFilterChange = (filter) => {
        getStockLogs(muiPage, null, pageOrigin, filter);
    };

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getStockLogs(1, null, pageOrigin);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: 'created_at',
            label: 'Date',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value) => {
                    return <span> {value.substr(0, 10)} </span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: '_id',
            label: 'ID',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value) => {
                    if (typeof value === 'string' && value.length > 4) {
                        return `${value.slice(0, 3)}...${value.slice(-3)}`;
                    }
                    return value;
                },
            },
        },
        {
            name: 'sku',
            label: 'SKU',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'product_name',
            label: 'Product Name',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'description',
            label: 'Description',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'quantity',
            label: 'Quantity',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'reference_no',
            label: 'Reference No.',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'supplier',
            label: 'Supplier',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'movement',
            label: 'Movement',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'quantity',
            label: 'Quantity',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: '',
            label: '',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,

                customBodyRender: (value, tableMeta, updateValue) => {
                    let stock_log = stock_logs[tableMeta['rowIndex']];

                    let { _id, reference_no, movement } =
                        stock_logs[tableMeta['rowIndex']];

                    let id = _id;

                    // Check if editing is allowed (movement is "out" and reference_no exists)
                    const canEdit = movement === 'out' && reference_no;

                    const button = (
                        <Link
                            className="btn btn-sm btn-grey"
                            style={{
                                cursor: canEdit ? 'pointer' : 'default',
                            }}
                            onClick={() =>
                                canEdit &&
                                setModal('return_stock_modal', true, stock_log)
                            }
                        >
                            <i
                                className="ft ft-edit"
                                style={{
                                    pointerEvents: canEdit ? 'auto' : 'none',
                                    opacity: canEdit ? 1 : 0.5,
                                }}
                            ></i>
                        </Link>
                    );

                    return (
                        <div className="btn-group" style={{ minWidth: '50px' }}>
                            {hoverIndex === tableMeta.rowIndex ? (
                                canEdit ? (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-${id}`}>
                                                Edit
                                            </Tooltip>
                                        }
                                    >
                                        {button}
                                    </OverlayTrigger>
                                ) : (
                                    button
                                )
                            ) : (
                                ''
                            )}
                        </div>
                    );
                },
            },
        },
    ];

    const _columns = updateMUISetting(
        'view-columns',
        'stock_logs',
        null,
        columns
    );

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        filter: false,
        selectableRows: false,
        searchOpen: true,
        searchProps: {
            onBlur: (e) => {
                console.log('onBlur!');
            },
            onKeyUp: (e) => {
                console.log('onKeyUp!');
            },
        },
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `stocklogs_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            // console.log("numberOfRows", numberOfRows);
            getStockLogs(null, numberOfRows);
            // updateMUISetting('rows', 'purchase_order', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getStockLogs(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                    break;
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'stock_logs',
                        null,
                        tableState.columns
                    );
                    break;
                case 'changeRowsPerPage':
                    // updateMUISetting('rows', 'purchase_order', tableState.rowsPerPage);
                    break;
                case 'onFilterDialogOpen':
                    break;
                case 'onSearchClose':
                    break;
                default:
                    // console.log(tableState);
                    console.log('action not handled.');
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="filter-container">
                    <div className="custom-search">
                        <i className="ft ft-search"></i>
                        <input
                            type="text"
                            name="search"
                            onKeyUp={handleSearchInputMUI}
                            className="custom-search-input"
                        />
                    </div>
                    <StockLogFilter
                        filterBy="stock_logs"
                        pageOrigin={pageOrigin}
                        onFilterChange={handleFilterChange}
                    />
                </div>
            );
        },

        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    return (
        <>
            <Show when="inv.setting.inventory.stocks">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={stock_logs}
                        columns={_columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>

            <ReturnStockModal
                className="modal-centered" // Centering class
                onHide={() => setModal('return_stock_modal', null)}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    stock_logs: state.stock_logs,
    muiPage: state.helper.muiPage,
    hoverIndex: state.helper.hoverIndex,
});

export default connect(mapStateToProps, {
    getStockLogs,
    handleHover,
    setMUIPage,
    updateMUISetting,
    sortTableByColumn,
    getSelectedRows,
    handleSearchInputMUI,
    setModal,
})(ReturnStockLogTable);
