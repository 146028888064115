import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MiniSpinner } from '../../layouts/Spinner';
import { goPage, BarCodeQty, donwloadBarcode} from '../../../redux/actions/receiving/receiving.actions';
import { Link } from 'react-router-dom';
import { globalDropDown } from '../../../redux/actions/helper/helper.actions';
import { keys } from '@material-ui/core/styles/createBreakpoints';

function ReceivingBarcodeModal ({ receiving: { loading, po_items, receivingData}, show, onHide, goPage,
	 globalDropDown, BarCodeQty, donwloadBarcode}) {
	
	const componentRef = useRef();

	return (
		<Modal centered dialogClassName="modal-wdth" show={show} onHide={onHide} size="lg" animation={true}>
		    <Modal.Header className="inv-bg-header">
				<div className="col-10 pr-0"> 
					<h2>Receiving No: {receivingData.item_receipt_no}</h2>
				</div>
				<div className="col-2 pr-0">
					<button onClick={onHide} className="btn btn-circle btn-inv m-close-btn cursor-pointer float-right"><i className="ft ft-x cursor-pointer"></i></button>
				</div>
	        </Modal.Header>
		    <Modal.Body className="unset-scroll p-0 m-0">
				<div className="sd-border">
					{
						loading ?
						<MiniSpinner/>
						:
						<>
						<div className="main-print-div">
							<div className="row">
								<div className="col-md-12 table-responsive ">
									<table className="table table-stripped table-condensed">
										<thead>
											<tr>
												<th>SKU</th>
												<th>Item</th>
												<th>Barcode</th>
												<th width="15%">Desired Copy</th>
											</tr>
										</thead>
										<tbody>
											{
												po_items.map((data,key) => {
													return(
														<tr key={key}>
															<td>{data.sku}</td>
															<td>
																<div className="mw-90">{data.product_name}</div>
																<div className="f-10">{data.description}</div>
																<div className="f-10">{data.brand}</div>
															</td>
															<td>{data.barcode}</td>
															<td><input type="number" className="form-control text-right" step="1" min="1" value={data.barcode_qty} name="barcode_qty" onChange={(e) =>BarCodeQty(e, key)} /></td>
														</tr>
													)
												})
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="d-fr pagination-print p-tb-1m">
							<button className="btn btn-primary pull-right" onClick={donwloadBarcode}>Download</button>
						</div>
						</>
	 				}
				</div>
		    </Modal.Body>
		</Modal>
	  )
}

const mapStateToProps = state => ({
	receiving: state.receiving
})

export default connect(mapStateToProps, { goPage, globalDropDown, BarCodeQty, donwloadBarcode})(ReceivingBarcodeModal)
