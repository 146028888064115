import React, { Fragment } from 'react';
import {
    dateFilterInput,
    dateFilterClear,
    handleDateFilterSubmit,
    handleSelectSalesType,
    getDailySales
} from '../../redux/actions/daily_sales/daily_sales.action';
import { connect } from 'react-redux';
import Select from 'react-select';

function SalesFilter({
    daily_sales: { date_from, date_to, sales_options, sales_option, isSearchable },
    dateFilterInput,
    dateFilterClear,
    handleDateFilterSubmit,
    filterBy,
    pageOrigin,
    handleSelectSalesType,
    getDailySales
}) {
    const handleSalesTypeChange = (selectedOption) => {
        handleSelectSalesType(selectedOption); // Dispatches action to update the sales option
        // Trigger fetching data based on the selected option
        getDailySales(1, null, pageOrigin);
    };

    return (
        <Fragment>
            <form
                onSubmit={(e) => handleDateFilterSubmit(e, filterBy, pageOrigin)}
            >
                <section className="row mt-1 f-regular">
                    <div className="col-sm-12 col-md-3">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">
                                From: <span className="text-danger">*</span>
                            </label>
                            <div className="col-8">
                                <input
                                    type="date"
                                    name="date_from"
                                    value={date_from}
                                    onChange={dateFilterInput}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">
                                To: <span className="text-danger">*</span>
                            </label>
                            <div className="col-8">
                                <input
                                    type="date"
                                    name="date_to"
                                    value={date_to}
                                    onChange={dateFilterInput}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-3">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">
                                Sales Type:
                            </label>
                            <div className="col-8">
                                <Select
                                    options={sales_options}
                                    onChange={handleSalesTypeChange} // Updates sales_option and refetches sales data
                                    value={sales_option}
                                    isSearchable={isSearchable}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-3">
                        <div className="btn-group" role="group">
                            <button className="btn btn-primary">Filter</button>
                            <a
                                onClick={() => dateFilterClear(filterBy, pageOrigin)}
                                className="btn btn-danger text-white"
                            >
                                <i className="ft ft-spinner"></i> Clear
                            </a>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    daily_sales: state.daily_sales,
});

export default connect(mapStateToProps, {
    dateFilterInput,
    dateFilterClear,
    handleDateFilterSubmit,
    handleSelectSalesType,
    getDailySales
})(SalesFilter);
