import React, { Fragment, useEffect, useMemo } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// import SalesOrderPrintModal from './modals/SalesOrder.modal.print';
import ReturnsStockLogsTable from '../returns/ReturnStockLog.table';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';

function Returns({ returns: { edit_url, edit_page, stock_logs } }) {
    // useMemo(() => {
    //     getSalesOrders();
    // },[])

    if (edit_page) return <Redirect to={edit_url} />;

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Returns{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a
                                            href="/sales/returns"
                                            className="text-white"
                                        >
                                            Product Stock Movement
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        {/* <Show when="inv.products.items.create">
                        <Link to="/products/items/create"  className="btn btn-primary pull-right btn-fix-header f-regular"><i className="ft ft-plus"></i> Create Product</Link>
                      </Show> */}
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="what-is" className="">
                                    <div className="card-content collapse show">
                                        <div className="card-body no-padding-top no-padding-left-right">
                                            <section className="row">
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs f-regular">
                                                        {/* old params 'all', '1', '2'. 're-stock' */}
                                                        <li className="">
                                                            <a
                                                                className="nav-link active"
                                                                id="base-tab1"
                                                                data-toggle="tab"
                                                                aria-controls="tab1"
                                                                href="#tabs"
                                                                aria-expanded="true"
                                                            >
                                                                Logs
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content px-1 pt-1 card-box-top card">
                                                        <div
                                                            role="tabpanel"
                                                            className="tab-pane active table-responsive "
                                                            id="tabs"
                                                            aria-expanded="true"
                                                            aria-labelledby="base-tab1"
                                                        >
                                                            <Show when="inv.sales.sales-orders.view">
                                                                <div className="content-body margin-top-5">
                                                                    <section id="basic-examples">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="card">
                                                                                    <div className="card-header">
                                                                                        <h4 className="card-title">
                                                                                            List
                                                                                            <a
                                                                                                href="#"
                                                                                                className="tool-tip"
                                                                                                data-tip="this is a tool tip"
                                                                                            >
                                                                                                <i className="la la-question-circle"></i>
                                                                                            </a>
                                                                                        </h4>
                                                                                    </div>
                                                                                    <div className="card-content collapse show">
                                                                                        <div className="card-body">
                                                                                            <Show when="inv.sales.sales-orders.view">
                                                                                                <section className="row f-regular">
                                                                                                    <div className="col-sm-12">
                                                                                                        <ReturnsStockLogsTable />
                                                                                                    </div>
                                                                                                </section>
                                                                                            </Show>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </Show>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    returns: state.returns,
});

export default connect(mapStateToProps, {})(Returns);
