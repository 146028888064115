import { getNumRows } from '../../../Utils/Common';
import * as TYPES from '../../types';
import { ReturnsService } from './_service.return';

// Set Loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });

// Set Modal
export const setModal =
    (modal, status, data = null) =>
    async (dispatch) => {
        dispatch({
            type: TYPES.SET_MODAL,
            payload: { modal, status, data },
        });
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
    // dispatch(getReceiving(1));
};

export const getStockLogs =
    (pageNumber, rows_per_page = null, pageOrigin, movement_filter = 'out') =>
    async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));

            let { search, pagination, sort_order_name, sort_order_direction } =
                getState().stock_logs;
            let rows_per_page_val =
                rows_per_page != null
                    ? rows_per_page
                    : getNumRows('stock_logs');

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let params = {
                search: search,
                options: {
                    page: pageNumber ? pageNumber : pagination.activePage,
                    limit: rows_per_page_val ? rows_per_page_val : 10,
                    sort_by: sort_order_name,
                    sort_order: sort_order_direction,
                },
                product_id: pageOrigin ? pageOrigin.product_id : null,
                movement: movement_filter,
            };

            ReturnsService.getStockLogs(params).then((result) => {
                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let payload = {
                    stock_logs: result.data.docs,
                    pagination: {
                        totalCount: result.data.total_docs,
                        activePage: result.data.page,
                        itemsCountPerPage: result.data.limit,
                        totalItemsCount: result.data.total_docs,
                    },
                };

                dispatch({ type: TYPES.GET_STOCK_LOGS_DATA, payload });
            });
        } catch (err) {
            console.log(err);
            dispatch(setLoading(false));
        }
    };

export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getStockLogs());
    };

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
    };

export const getSingleStockLog =
    (id, status = null) =>
    async (dispatch) => {
        if (!status) {
            dispatch(setLoading(true));
        }

        ReturnsService.getSingleStockLog(id)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_SINGLE_STOCK_LOG,
                    payload: res.data.stock_log,
                });

                if (!status) {
                    dispatch(setLoading(false));
                }
                // Open the modal after data is fetched
                dispatch({
                    type: TYPES.SET_MODAL,
                    payload: { modal: 'stockLogModal', status: true },
                });
            })
            .catch((error) => {
                console.log(error);
                if (!status) {
                    dispatch(setLoading(false));
                }
            });
    };
