import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

function returnStockModal({ show, onHide, modalData }) {
    if (!show) return null;

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Returns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Display the stock_log data here */}
                {modalData ? (
                    <>
                        <p>ID: {modalData._id}</p>
                        <p>Reference No: {modalData.reference_no}</p>
                        {/* Add additional fields as needed */}
                    </>
                ) : (
                    'No data available'
                )}
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    show: state.daily_sales.return_stock_modal,
    modalData: state.stock_logs.modalData,
});

export default connect(mapStateToProps)(returnStockModal);
