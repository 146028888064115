import * as TYPES from '../types';

const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    stock_logs: [],
    returns: [],
    tax_types: [
        { value: 'none', label: 'None' },
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
    ],
    subs_setting: null,
    new_uploads: [],
    loading: false,
    mini_loading: false,
    delete_modal: false,
    edit_row_index: null,
    main_page: false,
    edit_page: false,
    print_modal_si: false,
    main_url: '/sales/returns',
    create_url: '/sales/returns/create',
    edit_url: '/sales/returns/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    diselect_row: false,
    set_row_checked: false,
    search: '',
};

const returnsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_STOCK_LOGS:
            return {
                ...state,
                stock_logs: action.payload.stock_logs,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case TYPES.GET_RETURNS:
            return {
                ...state,
                returns: action.payload.returns,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        default:
            return state;
    }
};

export default returnsReducer;
